import "./style.css"
import pic from "../../../../assets/images/Home_block4_pic.png"
import {useNavigate} from "react-router-dom";

export default function HomeBlock4() {
    let navigate=useNavigate()
    return (
        <div className="container">
            <div className="container_inner">
                <div className="HomeBlock4_cont d-flex justify-content-center flex-wrap">
                    <img src={pic} className="HomeBlock4_cont_imgBlock"/>
                    <div className="d-flex fd-column">
                        <p className="f-700 fs-32 HomeBlock4_cont_title">Why do we do this?</p>
                        <p className="f-400 fs-18 HomeBlock4_cont_content">
                            As fellow ‘dropshippers’ we know how difficult it
                            sometimes can be to find that winning product.</p>
                            <p className="f-400 fs-18">
                                Thats why we’ve been creating our own search engine
                                to find the best and latest products! And ofcourse
                                it is a lot to process. Thats why we couldn’t bare the
                                idea that we would keep this to our own!</p>
                        <button className="CTA_button fs-16 f-400" onClick={()=>navigate('/login')}>Give me access</button>
                    </div>
                </div>
            </div>
        </div>
    )
}