import React, {useState} from 'react';
import { Route, Outlet , Navigate } from 'react-router-dom';

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    let [token, setToken] = useState(localStorage.getItem("jwtToken" )|| "");


    if (token) {
        return <Navigate to={"/my-account"} replace/>;
    }
    return <Outlet replace/>;

};

export default PublicRoute;