import "./style.css"
import {useState} from "react";
import video_pic1 from "../../../../assets/images/video1.png"
import search_icon from "../../../../assets/images/search_icon_adStock.png"
import medal_1 from "../../../../assets/images/medal1.png"
import media_pic from "../../../../assets/images/media_icon_adStock.png"


export default function HomeCarousel() {
    let [carouselItem, setCarouselItem] = useState([
        {
            id: 1,
            pic:video_pic1,
            title: "Daily Updated Products",
            description: "Our automated bot searches everyday for the best products available"
        },
        {
            id: 2,
            pic:search_icon,
            title: "Unlimited Searches",
            description: "Unlike most of the SpyTools, we do not have a limit on searches!"
        },
        {
            id: 3,
            pic:medal_1,
            title: "Find Winning Products",
            description: "With our simple overview you can search the best products in just a few clicks!"
        },
        {
            id: 4,
            pic:media_pic,
            title: "Made Easy For You",
            description: "Acces to everything you need. Product link, Ali link and instant video download without watermark!"
        },
    ])

    return (
        <div className="container">
            <div className="homeCarousel_cont">
                <p className="text-center f-700 fs-32 ">Why choose AD-STOCK?</p>
                <div className="omeCarousel_cont_items_block d-grid grid-columns-4fr grid-gab">
                    {carouselItem.map((item, index) => {
                        return (
                            <div className="homeCarousel_cont_item bc-white" key={item.id}>
                                <div
                                    className="homeCarousel_cont_item_inner d-flex fd-column align-items-center justify-content-center text-center">
                                    <img src={item.pic} className="homeCarousel_cont_item_img"/>
                                    <p className="f-700 fs-18 homeCarousel_cont_item_title">{item.title}</p>
                                    <p className="f-400 fs-16">{item.description}</p>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}
