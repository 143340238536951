import "./style.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useNavigate} from "react-router-dom"
import {useState,memo} from "react";
import axios from "axios";
import config from "../../config";
import useDocumentTitle from "../../useDocumentTitle";


export default function Login() {
    useDocumentTitle('Login')
    let navigate = useNavigate()
    const [login, setLogin] = useState({email: "", password: ""})
    const [error, setError] = useState("")
    const [loginBtn, setLoginBtn] = useState(false)


    const handleChangeEmail = (e, name) => {
        setLogin({...login, [name]: e})
        setError("")
    }

    const handleChangePassword = (e,name) => {
        setLogin({...login, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(login.email).toLowerCase());
    }
    const loginHandler = () => {
        let values={
            email: login.email,
            password: login.password,
        }
        setLoginBtn(true)
        if (login.email.length && login.password.length) {
            if (login.password.length > 7) {
                if (validateEmail()) {
                    axios.post(`${config.url}/login`,values)
                        .then(response => {
                            console.log(response,"response");
                            localStorage.setItem('jwtToken', response.data.access_token)
                            navigate("/my-account")
                        })
                        .catch(error => {
                            console.log(error.response)
                            setError("Incorrect email address or password")
                        })
                } else {
                    setError("Incorrect email address")
                }
            } else {
                setError("AccountPassword length is small")
            }
        }
    }

    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="login_container">
                    <div className="login_cont">
                        <div className="login_cont_inner">
                            <p className="text-center f-700 fs-32 ">Welcome back</p>
                            <div className="inputs_blocks_cont">
                                <div className="input_block d-flex fd-column">
                                    <label htmlFor="" className="f-400 fs-16">Email</label>
                                    <input type="email" placeholder="Enter your email address"
                                           style={!login.email && loginBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeEmail(e.target.value, "email")}/>
                                </div>
                                <div className="input_block d-flex fd-column">
                                    <label htmlFor="" className="f-400 fs-16">Password</label>
                                    <input type="password" placeholder="********"
                                           style={!login.email && loginBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangePassword(e.target.value, "password")}/>
                                </div>
                                <div className="forgot_pass f-400 fs-14 text-center"
                                     onClick={() => navigate("/reset-password-email")}>Forgot password?
                                </div>
                            </div>
                            {error ? <p className="error">{error}</p> : null}
                            <div
                                className="bc-blue c-white f-400 fs-16 d-flex justify-content-center login_cont_loginBtn"  onClick={loginHandler}>Login
                            </div>
                            <p className="f-400 fs-14 d-flex justify-content-center dont_account">Dont have an account?
                                <div className="c-greenBlue"
                                     onClick={() => navigate("/register")}
                                >Register</div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}