import "./style.css"
import {useState,useRef,useEffect} from "react";
import arrow_select_down from "../../assets/images/arrow_select_down.png"
import arrow_select_up from "../../assets/images/arrow_select_up.png"

export default function VSelectCountries(props) {
    let [selectDropDawn, setSelectDropDawn] = useState(false)
    let [selectCountry, setSelectCountry] = useState([])

    useEffect(() => {
        if(props.countries){
            setSelectCountry([...props.countries])
        }
    }, [props])


    const selectCountryRef=useRef(null)

    const closeSelectDropDawnRef=useRef(null);
    let showSelect = () => {
        setSelectDropDawn(!selectDropDawn)
        console.log("dgddg")
    }
    const handleClickOutSide = (e) => {
        let closeDropDawnRef=closeSelectDropDawnRef;
        let countryRef=selectCountryRef;
        if (countryRef.current && ! countryRef.current.contains(e.target) && ! closeDropDawnRef.current.contains(e.target)) {
        setSelectDropDawn(false)
    }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])

    return (
        <>
        <div className="select_cont" onClick={showSelect} ref={closeSelectDropDawnRef}>

            <div id="" className="vSelect fs-16 f-400" placeholder={props.placeholder}> {props.countryState} </div>
            {
                !selectDropDawn ?
                    <img src={arrow_select_down} alt="" className="select_dropdown_arrow"/>
                    :
                    <img src={arrow_select_up} alt="" className="select_dropdown_arrow"/>
            }
        </div>
        {
            selectDropDawn ?
                <div className="select_item_cont" ref={selectCountryRef}>
                    {selectCountry.map((item, index) => {
                        return (
                                <div className="select_item d-flex" key={index}>
                                    <input type="radio" id="" name="radioBtn" className="select_item_checkbox" onChange={()=> {
                                        props.setCountryState(item.country)
                                        props.changeFilterData({countries: [item.id]})
                                    }}/>
                                    <label className="select_item_checkmark" key={item.id} htmlFor="">{item.country}</label>
                                </div>
                        )
                    })
                    }
                </div>
                : null
        }
</>
)
}
