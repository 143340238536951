import "./style.css"
import {useState,useRef,useEffect} from "react";
import arrow_select_down from "../../assets/images/arrow_select_down.png"
import arrow_select_up from "../../assets/images/arrow_select_up.png"
import RangeSlider from "react-range-slider-input";

export default function VSelectViews(props) {

    let [selectDropDawn, setSelectDropDawn] = useState(false)
    let [minCount, setMinCount] = useState(0)
    let [maxCount, setMaxCount] = useState(0)
    let [maxCountRange, setMaxCountRange] = useState(0)
    const rangeRef = useRef(null);
    const numbersViewsRef=useRef(null)

    useEffect(() => {
        if(props.views){
            setMinCount(+props.views.min)
            setMaxCount(+props.views.max)
            setMaxCountRange(+props.views.max)
        }
    }, [props.views])

    const closeSelectDropDawnRef=useRef(null);
    let showSelect = () => {
        setSelectDropDawn(!selectDropDawn)
        console.log("dgddg")
    }
    const handleClickOutSide = (e) => {
        let closeDropDawnRef=closeSelectDropDawnRef;
        let viewsRef=numbersViewsRef;
        if ( viewsRef.current && ! viewsRef.current.contains(e.target) && ! closeDropDawnRef.current.contains(e.target)) {
            setSelectDropDawn(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])

    useEffect(() => {
        if(props.viewsState !== 'Number of views'){
            let time = setTimeout(() => {
                props.changeFilterData({views: [minCount,maxCount]})
            }, 800)
            return () => {
                clearTimeout(time)
            }
        }
    }, [minCount, maxCount])

    return (
        <>
            <div className="select_cont" onClick={showSelect} ref={closeSelectDropDawnRef}>

                <div id="" className="vSelect fs-16 f-400" placeholder={props.placeholder}>{props.viewsState}</div>
                {
                    !selectDropDawn ?
                        <img src={arrow_select_down} alt="" className="select_dropdown_arrow"/>
                        :
                        <img src={arrow_select_up} alt="" className="select_dropdown_arrow"/>
                }
            </div>
            {
                selectDropDawn ?
                    <div className="select_item_cont_likes" ref={numbersViewsRef}>
                        <div className="select_item d-flex fd-column" >
                            <div className="fs-16 f-400">From <span style={{marginLeft:"5px",marginRight:"5px"}}>{minCount}</span>to<span style={{marginLeft:"5px"}}>{maxCount}</span></div>
                                <div className="d-flex fd-column range_block">
                                    {maxCountRange ?
                                        <RangeSlider
                                            ref={rangeRef}
                                            min={0}
                                            max={maxCountRange}
                                            onInput={(val) => {
                                                setMinCount(val[0])
                                                setMaxCount(val[1])
                                                props.setViewsState(`${val[0]} - ${val[1]}`)
                                            }}
                                        />
                                        :
                                        null
                                    }
                                    <div className="fs-14 f-400 reset_btn" onClick={() => {
                                        setMinCount(0)
                                        setMaxCount(814)
                                    }}>Reset
                                    </div>
                                </div>
                            </div>
                    </div>
                    : null
            }
        </>
    )
}
