import "./style.css"
import VButton from "../../cors/button/v_button";
import cancel_icon from "../../assets/images/cancel_icon.png"
import VInput from "../../cors/input/v_input";
import search_icon from "../../assets/images/search_icon.png"
import {useState,useEffect} from "react";
import VSelectCountries from "../../cors/select/v_select_coutries";
import VSelectLikes from "../../cors/select/v_select_likes";
import VSelectViews from "../../cors/select/v_select_views";
import ReactCalendar from "../ReactCalendar/ReactCalendar";


export default function SearchAdsFilter(props) {
    const timestamp = Date.now();
    let [filterData, setFilterData] = useState({})
    let [countryState, setCountryState] = useState("All countries")
    let [likesState, setLikesState] = useState('Number of likes')
    let [viewsState, setViewsState]=useState("Number of views")
    let [firstDate, setFirstDate]=useState("First")
    let [lastDate, setLastDate]=useState("Last Ad date")
    const [startDate, setStartDate] = useState((new Date()));
    const [endDate, setEndDate] = useState(new Date());

    const [searchData, setSearchData] = useState({
        country: countryState,
        likes: likesState,
        views: viewsState,
        startDay: startDate,
        endDay: endDate,
    })


    useEffect(() => {
        let obj = searchData
        obj.country = countryState
        setSearchData({...obj})
    }, [countryState])

    useEffect(() => {
        let obj = searchData
        obj.likes = likesState
        setSearchData({...obj})
    }, [likesState])

    useEffect(() => {
        let obj = searchData
        obj.views = viewsState
        setSearchData({...obj})
    }, [viewsState])

    useEffect(() => {
        let obj = searchData
        obj.startDay = startDate
        setSearchData({...obj})
    }, [startDate])

    useEffect(() => {
        let obj = searchData
        obj.endDay = endDate
        setSearchData({...obj})
    }, [endDate])



    let ClearFilter = () => {
        setCountryState("All countries")
        setLikesState('Number of likes')
        setViewsState("Number of views")
        setFirstDate("First")
        setLastDate("Last Ad date")
        setStartDate(new Date())
        setEndDate(new Date())
    }

    let changeFilterData = (data) => {
        setFilterData({...filterData, ...data})
    }

    return (
        <div className="search_filter_cont d-flex justify-content-space-between flex-wrap">
            <div className="search_filter_cont_select">
                <VSelectCountries placeholder="All countries"
                                  countries={props.filterItems?.countries}
                                  countryState={countryState}
                                  changeFilterData={changeFilterData}
                                  setCountryState={setCountryState}/>
            </div>
            <div className="search_filter_cont_select">
                <VSelectLikes placeholder="Number of likes"
                              likes={props.filterItems?.likes}
                              changeFilterData={changeFilterData}
                              likesState={likesState}
                              setLikesState={setLikesState}/>
            </div>
            <div className="search_filter_cont_select">
                <VSelectViews placeholder="Number of views"
                              views={props.filterItems?.views}
                              viewsState={viewsState}
                              changeFilterData={changeFilterData}
                              setViewsState={setViewsState} setLastDate={setLastDate}/>
            </div>
            <div className="search_filter_cont_search d-flex align-items-center fs-16 f-400">
                <img src={search_icon} alt=""/>
                <VInput type="text" placeholder="Search for ad text" onChange={(e) => {
                    console.log(e, 'rwgkljbhnregkjn');
                    changeFilterData({search: e})
                }}/>
            </div>
                <ReactCalendar  firstDate={firstDate} setFirstDate={setFirstDate} lastDate={lastDate}
                                changeFilterData={changeFilterData}
                                startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
            <div className="clear_button_block d-flex align-items-center" onClick={ClearFilter}>
                <button className="clear_button fs-16 f-400 d-flex align-items-center justify-content-center"><img
                    src={cancel_icon} alt="" />Clear
                </button>
            </div>
            <div className="apply_button_block">
                <VButton title="Apply" onPress={() => {
                    props.filterReq(filterData)
                }}/>
            </div>
        </div>
    )
}
