import "./style.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import VButton from "../../cors/button/v_button";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import SearchItems from "../../components/SearchItems/SearchItems";
import axios from "axios";
import config from "../../config";
import useDocumentTitle from "../../useDocumentTitle";

export default function SavedAds(props) {
    useDocumentTitle('Saved Ads')
    let token = localStorage.getItem('jwtToken')
    let navigate = useNavigate()
    let [videos, setVideos] = useState([])

    useEffect(() => {
        addToFavourites()
    }, [])

    let addToFavourites = () => {

        axios.get(`${config.url}/get/favorites`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setVideos([...response.data.favorite]);
            }).catch(error => {
            console.log(error.response)
        })
    }

    let deleteFavourites = (item, index) => {
        console.log(item, "gfgfggg")
        axios.delete(`${config.url}/add/favorite/delete/${item.video_id}`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                console.log(response, 'rgfmnkln');
                videos.splice(index, 1)
                setVideos([...videos])
                window.location.reload()
            }).catch(error => {
            console.log(error.response)
        })

    }


    return (
        <div className="main">
            <div>
                <Header/>
                <div className="container">
                    <div className="container_inner">
                        <div className="saved_ads_cont d-flex fd-column align-items-center justify-content-center">
                            <p className="f-700 fs-32">Saved ads</p>
                            <div className="saved_ads_Item_block">
                                {videos.length === 0 ?
                                    <div className="d-flex justify-content-center fd-column align-items-center">
                                        <p className="f-400 fs-24" style={{marginTop: "8px"}}>You have no saved ads yet.
                                            Go to Feed to find something for you.</p>
                                        <div className="Feed_btn_block d-flex justify-content-center">
                                            <VButton title="Feed" onPress={() => navigate("/feed-page")}/>
                                        </div>
                                    </div>
                                    :
                                    <SearchItems
                                    videos={videos}
                                    deleteFavourites={deleteFavourites}

                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
