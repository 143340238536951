import "./style.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import useDocumentTitle from "../../useDocumentTitle";

export default function Terms(){
    useDocumentTitle('Terms of Services')
    return(
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <body>
                <div className="container">
                    <div className="refund_policy_cont">
                        <p className="f-700 fs-32 refund_policy_title">Terms of Service</p>
                        <div className="f-400 fs-18">
                            Not satisfied with your purchase? We are sorry to hear that!
                            You can request a refund within 14 days of your purchase. Contact us with your
                            reason and we will issue a refund within 5 business days.
                        </div>
                        <p className="f-700 fs-24 refund_policy_title2">Cancellation of Subscription</p>
                        <div className="f-400 fs-18">You may terminate your subscription at any time before your subscription renews by going into your account
                            settings on the WLspy website and pressing the 'Cancel Subscription' button. Unless we communicate otherwise,
                            following any cancellation your access will be terminated immediately.</div>
                        <ul className="refund_policy_ul">
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                        </ul>
                    </div>
                </div>
                </body>
            </div>
            <Footer/>
        </div>
    )
}