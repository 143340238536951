import React, {useState} from 'react';
import { Outlet, Navigate} from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");
    if (!token) {
        return <Navigate to={"/login"} replace/>;
    }
    return <Outlet replace/>;
};

export default PrivateRoute;
