import "./style.css"
import 'react-tabs/style/react-tabs.css';
import menu_burger from "../../assets/images/menu.png";
import {useRef, useState} from "react";

export default function NavBarTabs({tabHandler,toggleTabName}) {
    const[navbarShow, setNavbarShow]=useState(false)
    let closeNavbarRef=useRef()

    const handleClickNavbar =()=>{
        setNavbarShow(!navbarShow)
    }

    return (
        <div>
            <ul className="navbar_cont f-400 fs-16">
                <li  className={toggleTabName === 'account' ? "account_info_btn_active c-greenBlue" : "e account_info_btn"}
                     onClick={()=>tabHandler('account')}>Account info</li>
                <li className={toggleTabName === 'billing' ? 'billing_btn_active c-greenBlue ' : 'billing_btn'} onClick={()=>tabHandler('billing')}>
                    Billing
                </li>
                <li className={toggleTabName === 'invoice' ? 'invoice_btn_active c-greenBlue ' : 'invoice_btn'} onClick={()=>tabHandler('invoice')}>Invoice
                    settings
                </li>
                <li className={toggleTabName === 'whitelist' ? 'ip_btn_active c-greenBlue ' : 'ip_btn'} onClick={()=>tabHandler('whitelist')}>
                    IP Whitelist
                </li>
                <li  className={toggleTabName === 'password' ? "billing_btn_active c-greenBlue" : 'billing_btn'}
                     onClick={()=>tabHandler('password')}>Password</li>
            </ul>


            <ul className="navbar_cont_mobile f-400 fs-16">
                <img src={menu_burger} alt="" className="menu_burger_navbar" onClick={handleClickNavbar} ref={closeNavbarRef}/>

                { navbarShow &&
                    <div className="navbar_cont_content_mobile">
                        <li  className={toggleTabName === 'account' ? "account_info_btn_active c-greenBlue" : "e account_info_btn"}
                             onClick={()=>tabHandler('account')}>Account info</li>
                        <li className={toggleTabName === 'billing' ? 'billing_btn_active c-greenBlue ' : 'billing_btn'} onClick={()=>tabHandler('billing')}>
                            Billing
                        </li>
                        <li className={toggleTabName === 'invoice' ? 'invoice_btn_active c-greenBlue ' : 'invoice_btn'} onClick={()=>tabHandler('invoice')}>Invoice
                            settings
                        </li>
                        <li className={toggleTabName === 'whitelist' ? 'ip_btn_active c-greenBlue ' : 'ip_btn'} onClick={()=>tabHandler('whitelist')}>
                            IP Whitelist
                        </li>
                        <li  className={toggleTabName === 'password' ? "billing_btn_active c-greenBlue" : 'billing_btn'}
                             onClick={()=>tabHandler('password')}>Password</li>
                    </div>
                }

            </ul>
        </div>
    )
}