import "./style.css"
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useState} from "react";
import axios from "axios";
import config from "../../config";
import useDocumentTitle from "../../useDocumentTitle";

export default function ResetPasswordEmail() {
    useDocumentTitle('Reset Password Email')
    let navigate = useNavigate()
    const [resetPasswordEmail, setResetPasswordEmail] = useState({email: ""})
    const [error, setError] = useState("")
    const [resetPasswordEmailBtn, setResetPasswordEmailBtn] = useState(false)


    const handleChangeEmail = (e, name) => {
        setResetPasswordEmail({...resetPasswordEmail, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(resetPasswordEmail.email).toLowerCase());
    }
    const reset_PasswordEmail = () => {
        let values = {
            email: resetPasswordEmail.email,
        }
        console.log(error, "ryryr")
        setResetPasswordEmailBtn(true)
        if (resetPasswordEmail.email.length) {
            if (validateEmail()) {
                axios.post(`${config.url}/email/change/password`, values)
                    .then(response => {
                        console.log(response, "email password");
                        navigate("/reset-password")
                    })
                    .catch(error => {
                        console.log(error.response)
                        setError("Incorrect email address or password")
                    })
            } else {
                setError("Incorrect email address")
            }
        }
    }

    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="login_container">
                    <div className="login_cont">
                        <div className="login_cont_inner">
                            <p className="text-center f-700 fs-32 ">Reset Your Password</p>
                            <p className="text-center" style={{marginTop: "12px"}}>Please fill your Email and we will sent
                                password recovery link to Your Email address.</p>
                            <div className="inputs_blocks_cont">
                                <div className="input_block d-flex fd-column">
                                    <label htmlFor="" className="f-400 fs-16">Email</label>
                                    <input type="email" placeholder="Enter your email address"
                                           style={!resetPasswordEmail.email && resetPasswordEmailBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeEmail(e.target.value, "email")}/>
                                </div>
                            </div>
                            {error ? <p className="error">{error}</p> : null}
                            <div className="bc-blue c-white f-400 fs-16 d-flex justify-content-center login_cont_loginBtn"
                                 onClick={reset_PasswordEmail}>Send password reset link
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}