import "./style.css"
import {NavLink, useNavigate} from "react-router-dom"
import logo from "../../assets/images/logo.png"
import {useEffect, useRef, useState} from "react";
import VButton from "../../cors/button/v_button";
import axios from "axios";
import config from "../../config";
import menu_burger from "../../assets/images/menu.png"


export default function Header(props) {
    let navigate = useNavigate()
    const [activePage, setActivePage] = useState(false)
    const[dropdownShow, setDropDawnShow]=useState(false)
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");
    let closeHeaderDropDawnRef=useRef(null)
    let  closeHeaderDropDawnContentRef=useRef(null)

    console.log(document.title, "title")
    const LogoutPage = () => {
        let token = localStorage.getItem('jwtToken')
        console.log(token);
        axios.get(`${config.url}/logout`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                console.log(response, "logout");
                localStorage.removeItem("refreshToken")
                localStorage.removeItem("jwtToken")
                localStorage.removeItem("token")
                navigate("/login")
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    const handleClickDropdown =()=>{
        setDropDawnShow(!dropdownShow)
    }
    const handleClickOutSide = (e) => {
        let closeDropDawnRef=closeHeaderDropDawnRef;
        let contentRef=closeHeaderDropDawnContentRef;
        if (contentRef.current && ! contentRef.current.contains(e.target) && ! closeDropDawnRef.current.contains(e.target)) {
            setDropDawnShow(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])


    return (
        <>
            {!token ?
                <header className="bc-white header_login_container">
                    <div className="container">
                        <div className="container_inner">
                            <div
                                className="header_container_inner d-flex align-items-center justify-content-space-between">
                                <div onClick={() => navigate("/")}><img src={logo} alt="" className="logo"/></div>
                                <div
                                    className="d-flex align-items-center justify-content-space-between header_login_content f-400">
                                    <NavLink
                                        className={({isActive}) => isActive ? 'pricing_btn' : 'pricing_btn  c-greenBlue'}
                                        to="">Pricing</NavLink>
                                    <NavLink className={({isActive}) => isActive ? 'faq_btn' : 'faq_btn c-greenBlue'}
                                             to="">FAQ</NavLink>
                                    <NavLink
                                        className={({isActive}) => isActive ? 'contact_us_btn  c-greenBlue' : 'contact_us_btn'}
                                        to="/contact-us">Contact us</NavLink>
                                </div>
                                <div className="d-flex align-items-center log_reg_block fs-16 f-400">
                                    <div className="login_btn" onClick={() => navigate("/login")}>Log in</div>
                                    <div className="Register_btn">
                                        <VButton title={"Register"} onPress={() => navigate("/register")}/>
                                    </div>
                                    {/*<div className="Register_btn bc-blue c-white d-flex justify-content-center" >Register</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                :
                <header className="bc-white header_logout_container">
                    <div className="container">
                        <div className="container_inner">
                            <div
                                className="header_container_inner d-flex align-items-center justify-content-space-between">
                                <div onClick={() => navigate("/")}><img src={logo} alt="" className="logo"/>
                                </div>
                                <div
                                    className="d-flex align-items-center justify-content-space-between header_logout_content f-400">
                                    <NavLink className={({isActive}) => isActive ? 'feed_btn c-greenBlue' : 'feed_btn '}
                                             to="/feed-page">Feed</NavLink>
                                    <NavLink
                                        className={({isActive}) => isActive ? "saved_ads c-greenBlue" : 'saved_ads '}
                                        to="/saved-ads">Saved ads</NavLink>
                                    <NavLink
                                        className={({isActive}) => isActive ? 'my_account_btn c-greenBlue ' : 'my_account_btn '}
                                        to="/my-account">My account</NavLink>
                                    <NavLink
                                        className={({isActive}) => isActive ? 'contact_us_btn  c-greenBlue' : 'contact_us_btn'}
                                        to="/contact-us">Contact us</NavLink>
                                </div>
                                <div
                                    className="d-flex justify-content-center align-items-center log_reg_block fs-16 f-400">
                                    <div className="logout_btn" onClick={LogoutPage}>Log Out</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            }
            {
                !token ?
                    <header className="bc-white header_login_container_mobile">
                        <div className="container">
                            <div className="container_inner d-flex justify-content-space-between">
                                <div className="header_mobile_container_inner ">
                                    <div className="d-flex justify-content-space-between align-items-center">
                                        <div onClick={() => navigate("/")}><img src={logo} alt="" className="logo"/>
                                        </div>
                                   <div className="d-flex fd-column align-items-center header_dropDown ">
                                        <img src={menu_burger} alt="" className="menu_burger" onClick={handleClickDropdown} ref={closeHeaderDropDawnRef}/>
                                        {
                                            dropdownShow &&
                                            <div
                                                className="d-flex fd-column header_dropDown_content f-400" ref={closeHeaderDropDawnContentRef}>
                                                <NavLink className="pricing_btn_mobile" to="">Pricing</NavLink>
                                                <NavLink className="faq_btn_mobile" to="">FAQ</NavLink>
                                                <NavLink
                                                    className={({isActive}) => isActive ? 'contact_us_btn_mobile  c-greenBlue' : 'contact_us_btn_mobile'}
                                                    to="/contact-us">Contact us</NavLink>
                                                <NavLink className={({isActive}) => isActive ? "login_btn_mobile c-greenBlue": "login_btn_mobile"} to="/Login">Log in</NavLink>
                                                <NavLink className={({isActive}) => isActive ? "login_btn_mobile c-greenBlue": "login_btn_mobile"} to="/Register">Register</NavLink>
                                            </div>
                                        }

                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    :
                    <header className="bc-white header_logout_mobile_container">
                        <div className="container">
                            <div className="container_inner d-flex justify-content-space-between">
                                <div className="header_mobile_container_inner d-flex justify-content-space-between">
                                    <div onClick={() => navigate("/")}><img src={logo} alt="" className="logo"/></div>
                                    <div className="d-flex align-items-center header_dropDown">
                                        <img src={menu_burger} alt="" className="menu_burger" onClick={handleClickDropdown} ref={closeHeaderDropDawnRef}/>
                                        {
                                            dropdownShow &&
                                            <div className="d-flex fd-column header_dropDown_content f-400" ref={closeHeaderDropDawnContentRef}>
                                                <NavLink
                                                    className={({isActive}) => isActive ? 'feed_btn c-greenBlue' : 'feed_btn'}
                                                    to="/feed-page">Feed</NavLink>
                                                <NavLink
                                                    className={({isActive}) => isActive ? "saved_ads c-greenBlue" : 'saved_ads'}
                                                    to="/saved-ads">Saved ads</NavLink>
                                                <NavLink
                                                    className={({isActive}) => isActive ? 'my_account_btn c-greenBlue' : 'my_account_btn '}
                                                    to="/my-account">My account</NavLink>
                                                <NavLink
                                                    className={({isActive}) => isActive ? 'contact_us_btn  c-greenBlue' : 'contact_us_btn'}
                                                    to="/contact-us">Contact us</NavLink>
                                                <div className="logout_btn" onClick={LogoutPage}>Log Out</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

            }


        </>
    )
}
