import "./style.css"
import tik_tok_img from "../../../../assets/images/tik_tok_img.png"
import {useNavigate} from "react-router-dom";

export default function GetStarted() {
    let navigate=useNavigate()

    return (
        <div className="GetStarted_add_back">
            <div className="container">
                <div className="container_small getStarted_cont d-flex justify-content-space-between align-items-center flex-wrap">
                    <div className="d-flex fd-column align-items-start text-center_mobile">
                        <p className="getStarted_title f-700 fs-48">Find Top Winning Products on TikTok</p>
                        <p className="fs-18">With just a few clicks!</p>
                        <div className="c-white bc-blue fs-16 getStarted_btn d-flex justify-content-center"
                             onClick={()=>navigate("/login")}>Get started
                        </div>
                    </div>
                    <div className="getStarted_img_block">
                        <img src={tik_tok_img} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}
