import "./style.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useNavigate} from "react-router-dom";
import  contact_us_img from "../../assets/images/contact_us_message.png"
import useDocumentTitle from "../../useDocumentTitle";
export default function ContactUsMessage() {
    useDocumentTitle('Contact Us')
    let navigate=useNavigate()
    return (
        <>
            <Header isLogin={true}/>
            <body>
            <div className="contact_us_container">
                <div className="contact_us_cont">
                    <div className="contact_us_cont_inner">
                        <p className="text-center f-700 fs-32 ">Thank you!</p>
                        <div className="d-flex justify-content-center contact_us_message_imgBlock" >
                            <img src={contact_us_img} alt=""/>
                        </div>
                        <p className="message_sent text-center fs-18 f-400">Your message is sent successfully. We will answer you as soon as possible. Check your male.</p>
                        <div
                            className="bc-blue c-white f-400 fs-16 d-flex justify-content-center login_cont_loginBtn"
                            onClick={()=> navigate("/")}>Go to Homepage
                        </div>
                    </div>
                </div>
            </div>
            </body>
            <Footer/>
        </>
    )
}