import "./style.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import useDocumentTitle from "../../useDocumentTitle";


export default function AccountVerification() {
    useDocumentTitle('Account Verification')
    return (
        <>
            <Header isLogin={true}/>
            <body>
            <div className="login_container">
                <div className="login_cont account_verify_cont">
                    <div className="login_cont_inner">
                        <p className="text-center f-700 fs-32 ">Account verification</p>
                        <p className="text-center" style={{marginTop:"12px"}}>
                            We just sent an email confirmation to namesurname@yahoo.com </p>
                            <p className="text-center" style={{marginTop:"20px"}}>Just hit the button in that email to finalize the email verification process.
                            If you don't see it, please check the spam folders in your inbox.</p>
                        <div className="bc-blue c-white f-400 fs-16 d-flex justify-content-center login_cont_loginBtn account_verify_btn">Resend verification email</div>
                    </div>
                </div>
            </div>
            </body>
            <Footer/>
        </>
    )
}