import "./style.css"
import VButton from "../../../../cors/button/v_button";
import axios from "axios";
import config from "../../../../config";
import {useEffect, useState} from "react";

export default function AccountInfo(props) {
    let token = localStorage.getItem('jwtToken')
    const [user, setUser] = useState({...props.user})
    const [error, setError] = useState("")
    const [userBtn, setUserBtn] = useState(false)

    let handleChange = (value, name) => {
        setUser({...user, [name]: value})
        setError("")
    }
    console.log(user, "user")

    useEffect(() => {
        setUser({...props.user})
    }, [props])


    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(user?.email).toLowerCase());
    }

    let SaveChanges = () => {
        setUserBtn(true)
        if (user?.first_name && user?.last_name && user?.email &&
            user?.address && user?.city && user?.country && user?.zip_code && user?.company_name) {
            if (validateEmail()) {
                axios.post(`${config.url}/change/user/info`, user, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => {

                        console.log(response, "patasxan");
                    })
                    .catch(error => {
                        console.log(error, 'err')
                        console.log(error.response, 'res')
                        setError("Incorrect email address")
                    })
            } else {
                setError("Incorrect email address")
            }
        }
    }
    console.log(user, 'ghvhjvhj');

    return (
        <div className="account_info_cont " ref={props.closeAccountRef}>
            <p className="account_info_title f-400 fs-32">Hi,<span className="f-700">{user.first_name}</span></p>
            <div className="account_info_block_cont d-flex fd-column flex-wrap">
                <div className="account_info_block d-flex flex-wrap">
                    <div className="account_info_block_item d-flex fd-column">
                        <label htmlFor="">First name</label>
                        <input type="name" placeholder="" className="" value={user.first_name}
                               style={!user.first_name && userBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChange(e.target.value, "first_name")}/>
                    </div>
                    <div className="account_info_block_item d-flex fd-column">
                        <label htmlFor="">Last name</label>
                        <input type="name" placeholder=""
                               value={user.last_name}
                               style={!user.last_name && userBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChange(e.target.value, "last_name")}/>
                    </div>
                </div>
                <div className="account_info_block d-flex flex-wrap">
                    <div className="account_info_block_item d-flex fd-column">
                        <label htmlFor="">Email</label>
                        <input type="name" placeholder="" className="" value={user.email}
                               style={!user.email && userBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChange(e.target.value, "email")}/>
                    </div>
                    <div className="account_info_block_item d-flex fd-column">
                        <label htmlFor="">Address</label>
                        <input type="name" placeholder=""
                               value={user.address}
                               style={!user.address && userBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChange(e.target.value, "address")}/>
                    </div>
                </div>
                <div className="account_info_block d-flex flex-wrap">
                    <div className="account_info_block_item d-flex fd-column">
                        <label htmlFor="">City</label>
                        <input type="name" placeholder="" className=""
                               value={user.city}
                               style={!user.city && userBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChange(e.target.value, "city")}/>
                    </div>
                    <div className="account_info_block_item d-flex fd-column">
                        <label htmlFor="">Country</label>
                        <input type="name" placeholder="" value={user.country}
                               style={!user.country && userBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChange(e.target.value, "country")}/>
                    </div>
                </div>
                <div className="account_info_block d-flex flex-wrap">
                    <div className="account_info_block_item d-flex fd-column">
                        <label htmlFor="">Company</label>
                        <input type="name" placeholder="" className="" value={user.company_name}
                               style={!user.company_name && userBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChange(e.target.value, "company_name")}/>
                    </div>
                    <div className="account_info_block_item d-flex fd-column">
                        <label htmlFor="">Zip</label>
                        <input type="name" placeholder="" value={user.zip_code}
                               style={!user.zip_code && userBtn ? {border: "1px solid red"} : null}
                               onChange={(e) => handleChange(e.target.value, "zip_code")}/>
                    </div>
                </div>
            </div>
            {error ? <p className="error">{error}</p> : null}
            <div className="d-flex justify-content-end">
                <div className="save_changes_btn">
                    <VButton title="Save changes" className="c-white" onPress={SaveChanges}/>
                </div>
            </div>


        </div>
    )
}