import "./style.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import useDocumentTitle from "../../useDocumentTitle";

export default  function ContactUs(){
    useDocumentTitle('Contact Us')
    let navigate=useNavigate()

    return (
        <div className="main">
            <div>
                <Header/>
                <div className="contact_us_container">
                    <div className="contact_us_cont">
                        <div className="contact_us_cont_inner">
                            <p className="text-center f-700 fs-32 ">We are in touch!</p>
                            <div className="inputs_blocks_cont">
                                <div className="input_block d-flex fd-column">
                                    <label htmlFor="" className="f-400 fs-16">Name</label>
                                    <input type="name" placeholder="Enter your email address"/>
                                </div>
                                <div className="input_block d-flex fd-column">
                                    <label htmlFor="" className="f-400 fs-16">Email</label>
                                    <input type="email" placeholder="Enter your email address"/>
                                </div>

                                <div className="input_block d-flex fd-column">
                                    <label htmlFor="" className="f-400 fs-16">Message</label>
                                    <textarea name="" id="" cols="30" rows="10" placeholder="Tell us about what you think or ask us a question "> </textarea>
                                </div>
                            </div>
                            <div
                                className="bc-blue c-white f-400 fs-16 d-flex justify-content-center login_cont_loginBtn" onClick={()=> navigate("/contactus-message")}>Send
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}