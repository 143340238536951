import "./style.css"
import SubScribe_pic from "../../../../assets/images/subSribe_pic.png"
import {useNavigate} from "react-router-dom";
import VButton from "../../../../cors/button/v_button";
import VInput from "../../../../cors/input/v_input";


export default function SubScribe() {
    let navigate = useNavigate()
    return (
        <div className="container">
            <div className="container_inner">
                <div className="subScribe_block_cont d-flex justify-content-start align-items-center flex-wrap">
                    <img src={SubScribe_pic} className="subScribe_block_cont_imgBlock"/>
                    <div className="d-flex fd-column">
                        <p className="f-700 fs-32 HomeBlock4_cont_title">Subscribe for the latest updates</p>
                        <p className="f-400 fs-18 HomeBlock4_cont_content">
                            Be the first ti know about our updates and special offers.</p>
                        <VInput type="email" placeholder="Enter your email address"/>
                        <div className="subScribe_btn_block fs-16 f-400">
                            <VButton title={"SubScribe"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}