import "./style.css"
import waste_time_img from "../../../../assets/images/waste_time_pic.png"
import {useNavigate} from "react-router-dom";


export default function WasteTime() {
    let navigate=useNavigate()
    
    return (
        <div className="container">
            <div className="container_inner">
                <div className="HomeBlock2_cont d-flex justify-content-center flex-wrap">
                    <div className="d-flex fd-column">
                        <p className="f-700 fs-32 HomeBlock2_cont_title">Don’t waste any more time!</p>
                        <p className="f-400 fs-18 HomeBlock2_cont_content">With Ad-Stock you will get
                            instant access to the newest products published on TikTok and can easily
                            find them before anyine else! </p>
                           <p  className="f-400 fs-18 HomeBlock2_cont_content">
                               Use your account alone or share it with your team members.
                               This way you will get even more research done!</p>
                        <button className="give_access_btn  fs-16 f-400" onClick={()=>navigate("/login")}>Give me access</button>
                    </div>
                    <div className="HomeBlock2_cont_imgBlock">
                        <img src={waste_time_img} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}