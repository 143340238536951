import "./style.css"
import calendar_icon from "../../assets/images/calendar_icon.png"
import favourite_icon from "../../assets/images/favourite_icon.png"
import favourite_icon_full from "../../assets/images/favourite_icon_full.png"
import {useState} from "react";
import VButton from "../../cors/button/v_button";
import VideoBlock from "../VideoBlock/VideoBlock";
import "../Modal/modalSearchItems"
import ModalSearchItems from "../Modal/modalSearchItems";
import moment from "moment";


export default function SearchItems(props) {
    let [chooseVideo, setChooseVideo] = useState({})
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function openModal(data) {
        setChooseVideo(data)
        setModalIsOpen(true);
    }

    function closeModal() {
        setModalIsOpen(false);
    }

    const startDate=moment(props?.video?.country_id?.created_at).utc().format('DD/MM/YYYY')
    const endDate=moment(props?.video?.country_id?.updated_at).utc().format('DD/MM/YYYY')

    console.log(props.videos);
// console.log(favorite_id ,"favourite_id")

    return (
        <div className="container">
            <div className="searchItems_container d-grid">
                {props?.videos?.map((item, index) => {
                    return (
                        <div className="searchItems_block" key={item.id}>
                            <div className="searchItems_block_inner">
                                <div className="d-flex justify-content-space-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <img src={item.avatar_image} alt="" className="profile_pic"/>
                                        <p className="fs-14 f-400 profile_name">{item.profile_name}</p>
                                    </div>
                                    <img src={item?.favourite || item?.favorite_id ? favourite_icon_full : favourite_icon} alt=""
                                         onClick={() => {
                                             if(props.page === 'Feed'){
                                                 props.addFavourites(index)
                                             }else{
                                                 props.deleteFavourites(item, index)
                                             }
                                         }} className="fav_icon"/>
                                </div>
                                <div className="d-flex align-items-center justify-content-start calendar_block">
                                    <img src={calendar_icon} alt=""/>
                                    <div className="fs-14 f-400">{startDate} - {endDate}</div>
                                    {/*<div className="fs-14 f-400">{item.country_id.created_at} - {item.country_id.updated_at}</div>*/}
                                </div>
                                <div className="video_block">
                                    <VideoBlock src={item.video}/>
                                </div>
                                <div className="video_name_block d-flex justify-content-space-between">
                                    <p className="fs-14 f-400 video_name_block_title">{item.title}</p>
                                    <button className="video_name_btn fs-14 f-400">CTA</button>
                                </div>
                                <div className="likes_views_block d-flex justify-content-center">
                                    <div className="likes_block d-flex fd-column justify-content-center text-center">
                                        <div className="fs-18 f-700">{item.likes}</div>
                                        <p className="f-400 fs-14">Likes</p>
                                    </div>
                                    <div className="views_block d-flex fd-column justify-content-center text-center">
                                        <div className="fs-18 f-700">{item.views}</div>
                                        <p className="f-400 fs-14">Views</p>
                                    </div>
                                </div>
                                <VButton title="More details" onPress={() => {
                                    openModal(item)
                                }}/>
                            </div>
                        </div>
                    )
                })
                }
                <ModalSearchItems modalIsOpen={modalIsOpen}
                               setModalIsOpen={setModalIsOpen}
                               openModal={openModal}
                               closeModal={closeModal}
                               addFavourites={props.addFavourites}
                               video={chooseVideo} addToFavourites={props.addToFavourites}
                />
            </div>
        </div>
    )
}
