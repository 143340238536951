import "./style.css"
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useState} from "react";
import axios from "axios";
import config from "../../config";
import useDocumentTitle from "../../useDocumentTitle";

export default function ResetPassword() {
    useDocumentTitle('Reset Password')
    let navigate = useNavigate()
    const [resetPassword, setResetPassword] = useState({password: "", confirmPass:"", code:""})
    const [error, setError] = useState("")
    const [resetPasswordBtn, setResetPasswordBtn] = useState(false)


    const handleChangeCode = (e, name) => {
        setResetPassword({...resetPassword, [name]: e})
        setError("")
    }
    const handleChangePassword = (e, name) => {
        setResetPassword({...resetPassword, [name]: e})
        setError("")
    }
    const handleChangeConfirmPassword = (e, name) => {
        setResetPassword({...resetPassword, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(resetPassword.email).toLowerCase());
    }
    const reset_Password = () => {
        let values = {
            password: resetPassword.password,
            confirm_password:resetPassword.confirmPass,
            code:resetPassword.code,
        }
        console.log(error, "ryryr")
        setResetPasswordBtn(true)
        if (resetPassword.code.length && resetPassword.password.length && resetPassword.confirmPass.length ) {
            if (resetPassword.password.length > 7) {
                if( resetPassword.password === resetPassword.confirmPass) {
                    if (validateEmail()) {
                        axios.post(`${config.url}/change/password`, values)
                            .then(response => {
                                console.log(response, "change password");
                                navigate("/login")
                            })
                            .catch(error => {
                                console.log(error.response)
                                setError("Incorrect email address or password")
                            })
                    } else {
                        setError("Code is not valid")
                    }
                }
                else{
                setError(" Passwords are different")
            }
            }
            else{
                    setError("AccountPassword length is small")
                }
            }
        }

    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="login_container">
                    <div className="login_cont">
                        <div className="login_cont_inner">
                            <p className="text-center f-700 fs-32 ">Reset Your Password</p>
                            <div className="inputs_blocks_cont">
                                <div className="input_block d-flex fd-column">
                                    <label htmlFor="" className="f-400 fs-16">Enter a code</label>
                                    <input type="number" placeholder="Enter a code sent you via email"
                                           style={!resetPassword.code && resetPasswordBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeCode(e.target.value, "code")}/>
                                </div>
                                <div className="input_block d-flex fd-column">
                                    <label htmlFor="" className="f-400 fs-16">New password</label>
                                    <input type="password" placeholder="Enter your new password"
                                           style={!resetPassword.password && resetPasswordBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangePassword(e.target.value, "password")}/>
                                </div>
                                <div className="input_block d-flex fd-column">
                                    <label htmlFor="" className="f-400 fs-16">Repeat new password </label>
                                    <input type="password" placeholder="Repeat your new password"
                                           style={!resetPassword.confirmPass && resetPasswordBtn ? {border: "1px solid red"} : null}
                                           onChange={(e) => handleChangeConfirmPassword(e.target.value, "confirmPass")}/>
                                </div>
                            </div>
                            {error ? <p className="error">{error}</p> : null}
                            <div className="bc-blue c-white f-400 fs-16 d-flex justify-content-center login_cont_loginBtn"
                                 onClick={reset_Password}>Reset password
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}