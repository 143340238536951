import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
} from "react-router-dom"
import {useState} from "react";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login"
import ResetPasswordEmail from "./pages/RessetPasswordEmail/ResetPasswordEmail";
import Register from "./pages/Register/Register"
import AccountVerification from "./pages/AccountVerification/AccountVerification"
import ContactUs from "./pages/ContactUs/ContactUs";
import ContactUsMessage from "./pages/ContactUsMessage/ContactUsMessage";
import RefundPolicy from "./pages/RefundPolicy/RefandPolicy";
import CookiePolicy from "./pages/CookiePolicy/CookiePlocy";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPloicy";
import Terms from "./pages/Terms/Terms";
import MyAccount from "./pages/MyAccount/MyAccount";
import FeedPage from "./pages/FeedPage/FeedPage";
import SavedAds from "./pages/SavedAds/SavedAds";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import * as React from "react";
import PublicRoute from "./components/routing/PublicRoute";
import PrivateRoute from "./components/routing/PrivateRoute";
import useDocumentTitle from './useDocumentTitle'



function App() {
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");
    console.log(token, "tu68ututuyyu")

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route element={<PublicRoute/>}>
                        <Route element={<Login/>} path="/login" exact/>
                        <Route path="/reset-password-email" element={<ResetPasswordEmail/>}/>
                        <Route path="/reset-password" element={<ResetPassword/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/account-verification" element={<AccountVerification/>}/>

                    </Route>
                    <Route element={<PrivateRoute/>}>

                        <Route element={<MyAccount/>} path="/my-account" exact/>
                        <Route path="/feed-page" element={<FeedPage/>} exact/>
                        <Route path="/saved-ads" element={<SavedAds/>} exact/>
                    </Route>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/contactus-message" element={<ContactUsMessage/>}/>
                    <Route path="/contact-us" element={<ContactUs/>}/>
                    <Route path="/refund-policy" element={<RefundPolicy/>}/>
                    <Route path="/cookie-policy" element={<CookiePolicy/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
