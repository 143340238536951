import "./style.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import AccountInfo from "./components/AccountInfo/AccountInfo";
import AccountPassword from "./components/AccountPassword/AccountPassword";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../config";
import Login from "../Login/Login";
import useDocumentTitle from "../../useDocumentTitle";

export default function MyAccount(props){
    useDocumentTitle('My Account')
    let token = localStorage.getItem('jwtToken')
    let [user, setUser] = useState({})
    const [toggleTabName, setToggleTabName]=useState('account')

    const closeAccountRef=useRef(null)

    useEffect(() => {
        axios.get(`${config.url}/user`,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        ).then(response => {
            console.log(response, "respxxddddddddonse user")
            setUser(response.data)
        }).catch(error => {
            console.log(error.response)
        })
    }, [])
    const tabHandler = (name)=>{
        setToggleTabName(name)
    }

    return (
        <div className="main">
            <div>
                <Header isLogin={false}/>
                <div className="account_cont ">
                    <div className="account_cont_inner d-flex justify-content-space-between flex-wrap">
                        <NavBar closeAccountRef={closeAccountRef} tabHandler={tabHandler} toggleTabName={toggleTabName} />

                        {toggleTabName === 'account' ? <AccountInfo user={user} /> : null}
                        {toggleTabName === 'password' ? <AccountPassword user={user}/> : null}
                    </div>

                </div>
            </div>

            <Footer/>
        </div>
    )
}