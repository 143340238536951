import "./style.css"
import winning_products_img from "../../../../assets/images/winning_products_img.png"
import {useNavigate} from "react-router-dom";


export default function HomeBlock2() {
    let navigate=useNavigate()
    return (
        <div className="container">
            <div className="container_inner">
                <div className="HomeBlock2_cont d-flex justify-content-center flex-wrap">
                    <div className="d-flex fd-column">
                        <p className="f-700 fs-32 HomeBlock2_cont_title">Are you struggling finding winning products?</p>
                        <p className="f-400 fs-18 HomeBlock2_cont_content">Your struggling days wil be over soon! With
                            Ad-Stock you will find the best products on TikTok in just a few clicks trough our
                            search engine. From now on you can forget:</p>
                       <ul className="HomeBlock2_ul fs-18 f-400 ">
                           <li>Hours long research</li>
                           <li>Over-Saturated products</li>
                           <li>No right products to test</li>
                       </ul>
                        <button className="CTA_button fs-16 f-400" onClick={()=>navigate("/login")}>I want this</button>
                    </div>
                    <div className="HomeBlock2_cont_imgBlock">
                        <img src={winning_products_img} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}