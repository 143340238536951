import "./style.css"
import {Player} from 'video-react';
import {useState,useRef} from "react";
import play_btn from "../../assets/images/play_button.png"
import pause_btn from "../../assets/images/video-pause-button.png"


export default function VideoBlock(props) {
    let videoRef = useRef(null);
    let [videoPlayState, setVideoPlayState] = useState(false);

    let videoPlay = () => {
        setVideoPlayState(!videoPlayState);
        if (!videoPlayState) {
           videoRef.current.play()
        } else {
           videoRef.current.pause()
        }
    }


    return (
                <div className={"videoBlock_cont"}>
                    <video  className={"video_block_video"}  ref={videoRef}>
                        <source src={props.src} type="video/mp4"/>
                    </video>
                    <div className={"video_start"}>
                        <div className={"video_start_pic"}>
                            <img src={videoPlayState ? pause_btn : play_btn} alt="" className="play_btn"
                                 onClick={videoPlay}/>
                        </div>
                    </div>
            </div>
    )
}