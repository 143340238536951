import "./style.css"
import {useState} from "react";
import SwiperSlider from "../../../../components/Slider/SwiperSlider";



export default function CustomerReviews(props) {
    let [reviews,setReviews]=useState([
        {
            id:1,
            author:"Lorem ipsum",
            stars:"*****",
            opinion:"Lorem Ipsum is simply dummy text of the printing and typesetting industry"
        },
        {
            id:2,
            author:"Lorem ipsum",
            stars:"*****",
            opinion:"Lorem Ipsum is simply dummy text of the printing and typesetting industry"
        },
        {
            id:3,
            author:"Lorem ipsum",
            stars:"***",
            opinion:"Lorem Ipsum is simply dummy text of the printing and typesetting industry"
        },
        {
            id:4,
            author:"Lorem ipsum",
            stars:"***",
            opinion:"Lorem Ipsum is simply dummy text of the printing and typesetting industry"
        },
        {
            id:3,
            author:"Lorem ipsum",
            stars:"***",
            opinion:"Lorem Ipsum is simply dummy text of the printing and typesetting industry"
        },
    ])

    return (
        <div className="container">
            <div className="container_inner">
            <div className="reviews_cont">
                <p className="text-center f-700 fs-32 ">Customer reviews</p>
                <div className="reviews_cont_items_block d-flex">
                <SwiperSlider reviews={reviews} setReviews={setReviews}/>
                </div>
            </div>
            </div>
        </div>
    )
}
