import "./style.css"
import {useState, useRef, useEffect} from "react";
import arrow_select_down from "../../assets/images/arrow_select_down.png"
import arrow_select_up from "../../assets/images/arrow_select_up.png"
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

export default function VSelectLikes(props) {
    let [selectDropDawn, setSelectDropDawn] = useState(false)
    let [minCount, setMinCount] = useState(0)
    let [maxCount, setMaxCount] = useState(0)
    let [maxCountRange, setMaxCountRange] = useState(0)

    const closeSelectDropDawnRef = useRef(null);
    const rangeRef = useRef(null);
    const numbersLikesRef = useRef(null)

    let showSelect = () => {
        setSelectDropDawn(!selectDropDawn)
        console.log("dgddg")
    }

    useEffect(() => {
        if (props.likes) {
            setMinCount(+props.likes.min)
            setMaxCount(+props.likes.max)
            setMaxCountRange(+props.likes.max)
        }
    }, [props.likes])

    const handleClickOutSide = (e) => {
        let closeDropDawnRef = closeSelectDropDawnRef;
        let LikesRef = numbersLikesRef;
        if (LikesRef.current && !LikesRef.current.contains(e.target) && !closeDropDawnRef.current.contains(e.target)) {
            setSelectDropDawn(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])

    useEffect(() => {
        if (props.likesState !== 'Number of likes') {
            let time = setTimeout(() => {
                props.changeFilterData({likes:[ minCount, maxCount]})
            }, 800)
            return () => {
                clearTimeout(time)
            }
        }
    }, [minCount, maxCount])


    return (
        <>
            <div className="select_cont" onClick={showSelect} ref={closeSelectDropDawnRef}>

                <div id="" className="vSelect fs-16 f-400" placeholder={props.placeholder}>{props.likesState}</div>
                {
                    !selectDropDawn ?
                        <img src={arrow_select_down} alt="" className="select_dropdown_arrow"/>
                        :
                        <img src={arrow_select_up} alt="" className="select_dropdown_arrow"/>
                }
            </div>
            {
                selectDropDawn ?
                    <div className="select_item_cont_likes" ref={numbersLikesRef}>
                        <div className="select_item d-flex fd-column">
                            <div className="fs-16 f-400">From <span
                                style={{marginLeft: "5px", marginRight: "5px"}}>{minCount}</span>to<span
                                style={{marginLeft: "5px"}}>{maxCount}+</span></div>
                            <div className="d-flex fd-column range_block">
                                {maxCountRange ?
                                    <RangeSlider
                                        ref={rangeRef}
                                        min={0}
                                        max={maxCountRange}
                                        onInput={(val) => {
                                            setMinCount(val[0])
                                            setMaxCount(val[1])
                                            props.setLikesState(`${val[0]} - ${val[1]}`)
                                        }}
                                    />
                                    :
                                    null}
                                <div className="fs-14 f-400 reset_btn" onClick={() => {
                                    setMinCount(0)
                                    setMaxCount(848)
                                }}>Reset
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}
