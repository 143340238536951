import "./style.css"
import {useState} from "react";
import VButton from "../../../../cors/button/v_button";
import GetStarted from "../GetStarted/GetStarted";

export default function PriceList() {
    const [priceList, setPriceList] = useState([
        {
            id:1,
            version: "Monthly",
            currency:"$",
            price: "179",
            li1:"Daily updated products",
            li2: "Unlimited search",
            li3: "Find winning products",
            li4: "Made easy for you",
            li5: "Multi login - 3 slots",
        },
        {
            id:2,
            version: "Quarterly",
            currency:"$",
            price: "129",
            li1:"Daily updated products",
            li2: "Unlimited search",
            li3: "Find winning products",
            li4: "Made easy for you",
            li5: "Multi login - 3 slots",
        },
        {
            id:3,
            version: "Annual",
            currency:"$",
            price: "89",
            li1:"Daily updated products",
            li2: "Unlimited search",
            li3: "Find winning products",
            li4: "Made easy for you",
            li5: "Multi login - 3 slots",
        },
    ])
    const [priceListActive, setPriceListActive]=useState(null)

    return (
        <div className="container">
            <div className="container_small">
                <div className="priceList_cont d-flex fd-column align-items-center">
                    <p className="f-700 fs-32  text-center">Our Pricing Plans</p>
                    <p className="f-400 fs-16  text-center" style={{width:"60%"}}>These are the variables of plans we support
                        on our platform. You can choose to pay monthly, quarterly or annually. You are free to cancel your
                        plan at any moment.</p>
                    <div className="priceList_items_block d-flex justify-content-space-between align-items-center flex-wrap">
                        {priceList.map((item, index) => {
                            return (
                                <div onClick={() => setPriceListActive(index)} key={item.id} className="priceList_item bc-blueGrey">
                                    <div  className={priceListActive===index ? "bc-blueGrey" :"bc-white"}>
                                    <div className="priceList_item_inner d-flex fd-column align-items-start">
                                        <p className="fs-24 f-400">{item.version}</p>
                                        <div className="fs-24"><span className="fs-16">{item.currency}</span>{item.price}</div>
                                        <ul className="f-400 fs-16 priceList_ul">
                                            <li>{item.li1}</li>
                                            <li>{item.li2}</li>
                                            <li>{item.li3}</li>
                                            <li>{item.li4}</li>
                                            <li>{item.li5}</li>
                                        </ul>
                                        <VButton title={"Get Started"} onPress={() => alert()}/>
                                        {/*<div*/}
                                        {/*    className="priceList_getStarted_btn bc-blue c-white d-flex justify-content-center">Get*/}
                                        {/*    Started*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
