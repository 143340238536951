import "./style.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import config from "../../config";
import useDocumentTitle from "../../useDocumentTitle";

export default function Register() {
    useDocumentTitle('Register')
    let navigate = useNavigate()
    const [register, setRegister] = useState({
        name: "",
        surName: "",
        email: "",
        address: "",
        city: "",
        country: "",
        company:"",
        zip: "",
        password: "",
        confirmPass: "",
        policy:""
    })
    const [error, setError] = useState("")
    const [registerBtn, setRegisterBtn] = useState(false)


    const handleChangeName = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    const handleChangeSurName = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    const handleChangeEmail = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    const handleChangeAddress = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    const handleChangeCity = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    const handleChangeCountry = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    const handleChangeCompany = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    const handleChangeZip = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    const handleChangePassword = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    const handleChangeConfirmPassword = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    const handleChangePolicy = (e, name) => {
        setRegister({...register, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(register.email).toLowerCase());
    }

    let RegisterUser = () => {
        let values={
            first_name:register.name,
            last_name:register.surName,
            email: register.email,
            address:register.address,
            house_number:register.city,
            country:register.country,
            company_name:register.company,
            zip_code:register.zip,
            password: register.password,
            confirm_password: register.confirmPass,
        }
        console.log("dgdgdg")
        setRegisterBtn(true)
        if (register.name && register.surName && register.email.length &&
            register.address && register.city && register.country && register.zip
            && register.password.length && register.confirmPass && register.policy) {
            if (register.password.length > 7) {
                if( register.password === register.confirmPass){
                if (validateEmail()) {
                    console.log(values);
                    axios.post(`${config.url}/register`,values)
                        .then(response => {
                            console.log(response, "patasxan");
                            navigate("/login")
                        })
                        .catch(error => {
                            console.log(error, 'err')
                            console.log(error.response, 'res')
                            setError("Incorrect email address or password")
                        })
                } else {
                    setError("Incorrect email address")
                }
            }else{
                    setError("Passwords are different")
                }
            }else {
                setError("AccountPassword length is small")
            }
        }
    }


    return (
        <div className="main">
            <div>
                <Header isLogin={true}/>
                <div className="login_container">
                    <div className="register_cont">
                        <div className="register_cont_inner">
                            <p className="text-center f-700 fs-32 ">Join Us</p>
                            <div className="inputs_blocks_cont d-flex fd-column justify-content-space-between">
                                <div className="d-flex justify-content-space-between flex-wrap">
                                    <div className="input_block d-flex fd-column">
                                        <label htmlFor="" className="f-400 fs-16 d-flex">First Name<p
                                            className="c-red">*</p></label>
                                        <input type="name" placeholder="Enter your name"
                                               style={!register.name && registerBtn ? {border: "1px solid red"} : null}
                                               onChange={(e) => handleChangeName(e.target.value, "name")}/>
                                    </div>
                                    <div className="input_block d-flex fd-column">
                                        <label htmlFor="" className="f-400 fs-16 d-flex">Last Name<p className="c-red">*</p>
                                        </label>
                                        <input type="surname" placeholder="Enter your surname"
                                               style={!register.surName && registerBtn ? {border: "1px solid red"} : null}
                                               onChange={(e) => handleChangeSurName(e.target.value, "surName")}/>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-space-between flex-wrap">
                                    <div className="input_block d-flex fd-column">
                                        <label htmlFor="" className="f-400 fs-16 d-flex">Email<p className="c-red">*</p>
                                        </label>
                                        <input type="email" placeholder="Enter your email address"
                                               style={!register.email && registerBtn ? {border: "1px solid red"} : null}
                                               onChange={(e) => handleChangeEmail(e.target.value, "email")}/>
                                    </div>
                                    <div className="input_block d-flex fd-column">
                                        <label htmlFor="" className="f-400 fs-16 d-flex">Address<p className="c-red">*</p>
                                        </label>
                                        <input type="address" placeholder="Enter your address"
                                               style={!register.address && registerBtn ? {border: "1px solid red"} : null}
                                               onChange={(e) => handleChangeAddress(e.target.value, "address")}/>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-space-between flex-wrap">
                                    <div className="input_block d-flex fd-column">
                                        <label htmlFor="" className="f-400 fs-16 d-flex">City<p className="c-red">*</p>
                                        </label>
                                        <input type="city" placeholder="Enter city"
                                               style={!register.city && registerBtn ? {border: "1px solid red"} : null}
                                               onChange={(e) => handleChangeCity(e.target.value, "city")}/>
                                    </div>
                                    <div className="input_block d-flex fd-column">
                                        <label htmlFor="" className="f-400 fs-16 d-flex d-flex">Country<p
                                            className="c-red">*</p></label>
                                        <select name="" id="" className="measure_noise_select"
                                                style={!register.country && registerBtn ? {border: "1px solid red"} : null}
                                                onChange={(e) => handleChangeCountry(e.target.value, "country")}>
                                            <option value="1">Select your Country</option>
                                            <option value="2">France</option>
                                            <option value="3">England</option>
                                            <option value="3">German</option>
                                            <option value="3">Ukraine</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-space-between flex-wrap">
                                    <div className="input_block d-flex fd-column">
                                        <label htmlFor="" className="f-400 fs-16">Company</label>
                                        <input type="name" placeholder="Enter your company name"
                                               style={!register.company && registerBtn ? {border: "1px solid red"} : null}
                                               onChange={(e) => handleChangeCompany(e.target.value, "company")}/>
                                    </div>
                                    <div className="input_block d-flex fd-column">
                                        <label htmlFor="" className="f-400 fs-16 d-flex">ZIP<p className="c-red">*</p>
                                        </label>
                                        <input type="zip" placeholder="Enter ZIP code"
                                               style={!register.zip && registerBtn ? {border: "1px solid red"} : null}
                                               onChange={(e) => handleChangeZip(e.target.value, "zip")}/>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-space-between flex-wrap">
                                    <div className="input_block d-flex fd-column">
                                        <label htmlFor="" className="f-400 fs-16 d-flex">Password<p className="c-red">*</p>
                                        </label>
                                        <input type="password" placeholder="********"
                                               style={!register.password && registerBtn ? {border: "1px solid red"} : null}
                                               onChange={(e) => handleChangePassword(e.target.value, "password")}/>
                                    </div>
                                    <div className="input_block d-flex fd-column">
                                        <label htmlFor="" className="f-400 fs-16 d-flex">Repeat Password<p
                                            className="c-red">*</p></label>
                                        <input type="password" placeholder="********"
                                               style={!register.confirmPass && registerBtn ? {border: "1px solid red"} : null}
                                               onChange={(e) => handleChangeConfirmPassword(e.target.value, "confirmPass")}/>
                                    </div>
                                </div>
                                <div className="d-flex terms_block_general flex-wrap">
                                    <label className="terms_block d-flex f-400 fs-14 text-center" >By creating account I agree
                                        with companies
                                        <input type="checkbox"
                                               onChange={(e) => handleChangePolicy(e.target.value, "policy")}/>
                                        <span className="checkmark" style={!register.policy && registerBtn ? {border: "10px solid red"} : null}> </span>
                                    </label>
                                    <a className="c-greenBlue" href={"/privacy-policy"} target="_blank">Privacy Policy</a> and <a className="c-greenBlue" href={"/terms"} target="_blank">Terms and
                                    Conditions.</a>
                                </div>
                            </div>
                            {error ? <p className="error">{error}</p> : null}
                            <div className="d-flex justify-content-center align-items-center">
                                <div
                                    className="bc-blue c-white f-400 fs-16 d-flex justify-content-center register_cont_registerBtn"
                                    onClick={RegisterUser}>Register
                                </div>
                            </div>
                            <p className="f-400 fs-14 d-flex justify-content-center dont_account">Already have an account?
                                <div className="c-greenBlue f-600" onClick={() => (navigate("/login"))}> Login</div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )

}