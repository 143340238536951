import "./style.css"
import VButton from "../../../../cors/button/v_button";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../../config";
import pass_hidden from '../../../../assets/images/hidden_icon.png'
import pass_show from "../../../../assets/images/show_icon.png"

export default function AccountPassword(props) {
    let token = localStorage.getItem('jwtToken')
    const [showPassword, setShowPassword]=  useState(false)
    const [togglePass, setTogglePass]=useState("current")
    // const [user, setUser] = useState({...props.user})
    const [error, setError] = useState("")
    const [userBtn, setUserBtn] = useState(false)
    const[savePassword, setSavePassword]=useState({
        current_password:"",
        new_password:"",
        repeat_password:""
    })
    const handleChange = (e, name) => {
        setSavePassword({...savePassword, [name]: e})
        setError("")
    }


    const SavePasswordChanges = () => {

        setUserBtn(true)
        if (savePassword?.current_password && savePassword?.new_password && savePassword?.repeat_password) {
            if (savePassword?.new_password.length > 7) {
                if (savePassword?.new_password === savePassword?.repeat_password) {
                    console.log(savePassword.current_password, savePassword.new_password);
                    let values={
                        current_password: savePassword.current_password,
                        new_password: savePassword.new_password,
                    }
                    axios.post(`${config.url}/profile/change/password`, values, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            console.log(response, "patasxanPasword");
                            console.log(savePassword.new_password, "newPassword")
                        })
                        .catch(error => {
                            console.log(error, 'err')
                            console.log(error.response, 'res')
                            setError(error.response.data.message)
                        })
                } else {
                    setError("Passwords are different")
                }
            } else {
                setError("Password length is small")
            }
        }
    }

    let  _passHandler =(name,value) =>{
        setTogglePass(name)
        setShowPassword(!showPassword)
    }
    // console.log(user?.current_password, "current pass")

    return (
        <div className="account_pass_cont d-flex fd-column justify-content-center">
            <p className="account_pass_title f-700 fs-32">Password</p>
            <div className="d-flex justify-content-space-between flex-wrap">
                <div className="account_pass_input_block d-flex fd-column  ">
                    <label htmlFor="">Current Password</label>
                    <input type={togglePass==='current' && showPassword ? "text" :"password"} placeholder=""
                           style={!savePassword.current_password && userBtn ? {border: "1px solid red"} : null}
                           onChange={(e) => handleChange(e.target.value, "current_password")}/>
                    <img src={togglePass==='current' && showPassword ? pass_show : pass_hidden } alt="" onClick={()=>_passHandler("current")}/>
                </div>
                <div className="account_pass_input_block d-flex fd-column">
                    <label htmlFor="">New Password</label>
                    <input type={togglePass==='new' && showPassword ? "text" :"password"} placeholder=""
                           style={! savePassword.new_password && userBtn ? {border: "1px solid red"} : null}
                           onChange={(e) => handleChange(e.target.value, "new_password")}/>
                    <img src={togglePass==='new' && showPassword ? pass_show : pass_hidden } alt="" onClick={()=>_passHandler("new")}/>
                </div>
            </div>
            <div>
                <div className="account_pass_input_block d-flex fd-column">
                    <label htmlFor="">Repeat Password</label>

                    <input type={togglePass==="repeat" && showPassword ? "text" :"password"} placeholder=""
                           style={! savePassword.repeat_password && userBtn ? {border: "1px solid red"} : null}
                           onChange={(e) => handleChange(e.target.value, "repeat_password")}/>
                    <img src={togglePass==="repeat" && showPassword ? pass_show : pass_hidden } alt="" onClick={()=>_passHandler("repeat")}/>
                </div>
            </div>
            {error ? <p className="error" style={{textAlign:"left", marginLeft:"20px"}}>{error}</p> : null}
            <div className="password_changes_block d-flex justify-content-end">
                <VButton title="Save password changes" onPress={SavePasswordChanges}/>
            </div>
        </div>
    )
}