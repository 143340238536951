import Modal from 'react-modal';
import close_icon from "../../assets/images/x_icon.png";
import {useEffect, useState} from "react";
import "./style.css"
import profile_pic from "../../assets/images/profile_pic.png"
import favourite_icon_full from "../../assets/images/favourite_icon_full.png";
import favourite_icon from "../../assets/images/favourite_icon.png";
import calendar_icon from "../../assets/images/calendar_icon.png";
import VideoBlock from "../VideoBlock/VideoBlock";
import arrow_top from "../../assets/images/arrow_top.png";
import arrow_down from "../../assets/images/arrow_down.png";
import country_flag from "../../assets/images/country_flag.png"
import VButton from "../../cors/button/v_button";
import store_img from "../../assets/images/store_img.png"
import original_link from "../../assets/images/bx_link.png"
import download_icon from "../../assets/images/dawnload_icon.png"
import speakerphone_icon from "../../assets/images/speakerphone_icon.png"
import moment from "moment";
import axios from "axios";
import config from "../../config";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        Width: "100%",
        width: "calc(100%-30px)",
    },
};

export default function ModalSearchItems(props) {
    let [show, setShow] = useState(true);
    let [showDesc, setShowDesc] = useState(true);

    const startDate=moment(props?.video?.country_id?.created_at).utc().format('DD/MM/YYYY')
    const endDate=moment(props?.video?.country_id?.updated_at).utc().format('DD/MM/YYYY')


    return (
        <>
            <Modal
                isOpen={props.modalIsOpen}
                // onAfterOpen={props.afterOpenModal}
                onRequestClose={props.closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="modal_container">
                    <div className="modal_container_inner d-flex fd-column ">
                        <div className="d-flex justify-content-space-between">
                            <div className="d-flex align-items-center">
                                <img src={props?.video?.avatar_image} alt="" className="modal_profile_pic"/>
                                <p className="f-400 fs-18">{props?.video?.profile_name}</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <img src={props?.video?.favourite ? favourite_icon_full : favourite_icon} alt=""
                                     onClick={props.addFavourites} className="fav_icon"/>


                                <img src={close_icon} alt="close" className={"close_icon"}
                                     onClick={() => {
                                         props.setModalIsOpen(false)
                                     }}/>
                            </div>
                        </div>
                        <div className=" modal_blocks d-flex">
                            <div className="modal_video">
                                <VideoBlock src={props?.video?.video}/>
                            </div>
                            <div className="modal_blocks_2 d-flex fd-column" style={{width: "100%"}}>
                                <div className="d-flex align-items-center justify-content-start calendar_block">
                                    <img src={calendar_icon} alt=""/>
                                    <div className="fs-16 f-400">{startDate}- {endDate}</div>
                                </div>
                                <div className="modal_video_name_block d-flex justify-content-space-between">
                                    <p className="fs-14 f-400">{props?.video?.title}</p>
                                    <button className="video_name_btn fs-14 f-400">CTA</button>
                                </div>
                                <div className="modal_likes_views_block d-flex justify-content-center">
                                    <div
                                        className="modal_likes_block d-flex fd-column justify-content-center text-center">
                                        <div className="fs-18 f-700">{props?.video?.likes}</div>
                                        <p className="f-400 fs-14">Likes</p>
                                    </div>
                                    <div
                                        className="modal_views_block d-flex fd-column justify-content-center text-center">
                                        <div className="fs-18 f-700">{props?.video?.views}</div>
                                        <p className="f-400 fs-14">Views</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="modal_accordion">
                                        {/*{accordionItems.map((item, index) => {*/}
                                        {/*    return (*/}
                                        <div onClick={() => setShow(!show)}>
                                            {/*<div className={"accordion_block"} onClick={() => setShow(!show)}>*/}
                                            {/*    <div*/}
                                            {/*        className="d-flex justify-content-space-between align-items-center">*/}
                                            {/*        <div*/}
                                            {/*            className={"accordion_title f-700 fs-18"}>Ad copy*/}
                                            {/*        </div>*/}
                                            {/*        {show ?*/}
                                            {/*            <img src={arrow_top} alt=""/>*/}
                                            {/*            :*/}
                                            {/*            <img src={arrow_down} alt=""/>*/}
                                            {/*        }*/}
                                            {/*    </div>*/}
                                            {/*    {show ?*/}
                                            {/*        <div className={"accordion_desc_block"}>*/}
                                            {/*            {show ?*/}
                                            {/*                <div*/}
                                            {/*                    className="accordion_description fs-18 f-400">*/}
                                            {/*                    Lorem Ipsum is simply dummy text of the printing and typesetting*/}
                                            {/*                    industry. Lorem Ipsum has been the industry's standard dummy*/}
                                            {/*                    text ever since the 1500s, when an unknown printer took a galley*/}
                                            {/*                    of type and scrambled it to make a type specimen.</div>*/}
                                            {/*                : null*/}
                                            {/*            }*/}
                                            {/*        </div>*/}
                                            {/*        : null*/}
                                            {/*    }*/}
                                            {/*</div>*/}
                                        </div>
                                        <div onClick={() => setShowDesc(!showDesc)}>
                                            <div className={"accordion_block"} onClick={() => setShowDesc(!showDesc)}>
                                                <div
                                                    className="d-flex justify-content-space-between align-items-center">
                                                    <div
                                                        className={"accordion_title f-700 fs-18"}>Description
                                                    </div>
                                                    {showDesc ?
                                                        <img src={arrow_top} alt=""/>
                                                        :
                                                        <img src={arrow_down} alt=""/>
                                                    }
                                                </div>
                                                {showDesc ?
                                                    <div className={"accordion_desc_block"}>
                                                        {showDesc ?
                                                            <div
                                                                className="accordion_description fs-18 f-400">
                                                                {props?.video?.description}</div>
                                                            : null
                                                        }
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ad_country_block  d-flex">
                                        <div className="country_flag_block">
                                            <img src={props?.video?.country_id?.country_flag}
                                                 alt=""/>
                                        </div>
                                        <div className="d-flex fd-column">
                                            <div className='fs-16 f-400'>{props?.video?.country_id?.country}</div>
                                            <div className="share_ad_btn_block">
                                                <VButton title="Share ad"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="links_block justify-content-center d-flex">
                                        <div className="d-flex fd-column  links_block1">
                                            <div className="d-flex fd-column links_block1_1">
                                                <p>Store</p>
                                                <div className="d-flex align-items-center"><img src={store_img} alt=""/><a
                                                    href={props?.video?.store_link} className="fs-16 f-400 c-greenBlue">Product Page</a></div>
                                            </div>
                                            <div className="d-flex fd-column links_block1_2">
                                                <p>AliExpress</p>
                                                <div className="d-flex align-items-center"><img src={speakerphone_icon} alt=""/>
                                                    <a href={props?.video?.aliexpress_link} className="fs-16 f-400 c-greenBlue">Click here</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex fd-column links_block2">
                                            <div className="d-flex fd-column links_block2_1">
                                                <p>Original Link</p>
                                                <div className="d-flex align-items-center"><img src={original_link} alt=""/>
                                                    <a href={props?.video?.original_link} className="fs-16 f-400 c-greenBlue">Go to TikTok</a></div>
                                            </div>
                                            <div className="d-flex fd-column links_block2_2">
                                                <p>Download video</p>
                                                <div className="d-flex align-items-center"><img src={download_icon} alt=""/>
                                                    <a href={props?.video?.download} className="fs-16 f-400 c-greenBlue">Download</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
