import {useEffect} from 'react'
import "./style.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import GetStarted from "./components/GetStarted/GetStarted";
import HomeCarousel from "./components/HomeCarusel/HomeCarousel";
import PriceList from "./components/PriceList/PriceList";
import Questions from "./components/QuesrtionsAccordion/Questions";
import HomeBlock4 from "./components/HomeBlock4/HomeBlock4";
import HomeBlock2 from "./components/HomeBlock2/HomeBlock2";
import CustomerReviews from "./components/CustomerReviews/CustomerReviews";
import WasteTime from "./components/WasteTime/WasteTime";
import SubScribe from "./components/SubScribe_Block/SubScribe";
import useDocumentTitle from "../../useDocumentTitle";


export default function Home(){
    useDocumentTitle('Home')
    return(
        <div>
            <Header isLogin={true}/>
            <GetStarted/>
            <HomeBlock2/>
            <HomeCarousel/>
            <HomeBlock4/>
            <PriceList/>
            <WasteTime/>
            <SubScribe/>
            <CustomerReviews/>
            <Questions/>
            <Footer/>
        </div>
    )
}
