import "./style..css"
import {useState} from "react";
import arrow_down from "../../../../assets/images/arrow_down.png";
import arrow_top from "../../../../assets/images/arrow_top.png"

export default function Questions() {
    let [show, setShow] = useState(true);
    let [showActive, setShowActive] = useState(0);
    let [accordionItems, setAccordionItems] = useState([
        {
            id: 1,
            accordionTitle: "Lorem ipsum dolor sit amet?",
            accordionDesc: `Lorem Ipsum is simply dummy text of the printing and typesetting 
                industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
        },
        {
            id: 2,
            accordionTitle: "Lorem ipsum dolor sit amet?",
            accordionDesc: `Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
        },
        {
            id: 3,
            accordionTitle: "Lorem ipsum dolor sit amet?",
            accordionDesc: `Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
        },
        {
            id: 4,
            accordionTitle: "Lorem ipsum dolor sit amet?",
            accordionDesc: `Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
        },
    ])

    return (
        <div className="container">
            <div className="container_small">
                <div className="questions_cont d-flex justify-content-space-between flex-wrap">
                    <div  className="text-center_mobile">
                        <p className="fs-32 f-700">Frequently asked questions</p>
                        <p className="f-400 fs-18 d-flex flex-wrap">Can’t find the answer you’re looking for?
                            <div className="c-greenBlue" style={{marginLeft:"5px"}}>We are in touch.</div></p>
                    </div>
                    <div className={`accordion`}>
                        {accordionItems.map((item, index) => {
                            console.log(index , "omomom")
                            return (
                                <div onClick={() => setShowActive(index)} key={item.id}>
                                    <div className={"accordion_block"}  onClick={() => setShow(!show)}>
                                        <div className="d-flex justify-content-space-between align-items-center">
                                            <div
                                                className={"accordion_title f-700 fs-18"}>{item.accordionTitle}</div>
                                            {show ?
                                                <img src={arrow_top} alt=""/>
                                                :
                                                <img src={arrow_down} alt=""/>
                                            }
                                        </div>
                                        {showActive === index ?
                                            <div className={"accordion_desc_block"}>
                                                {show ?
                                                    <div
                                                        className="accordion_description fs-18 f-400">{item.accordionDesc}</div>
                                                    : null
                                                }
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    </div>
                </div>
        </div>
    )
}