import "./style.css"
import {NavLink, useNavigate} from "react-router-dom";


export default function Footer() {
    let navigate=useNavigate()
    return (
        <>
        <footer className="bc-blue footer_cont">
            <div className="container">
                <div className="container_inner">
                <div className="footer_cont_inner d-flex justify-content-space-between align-items-center">
                    <div className="fs-18 d-flex align-items-center footer_items_block c-white f-400">
                        <NavLink className={({isActive}) => isActive ? 'footer_item c-greenBlue' : 'footer_item c-white'} to="/refund-policy">Refund Policy</NavLink>
                        <NavLink className={({isActive}) => isActive ? 'footer_item c-greenBlue' : 'footer_item c-white'} to="/cookie-policy">Cookie policy</NavLink>
                        <NavLink className={({isActive}) => isActive ? 'footer_item c-greenBlue' : 'footer_item c-white'} to="/terms">Terms of Service</NavLink>
                        <NavLink className={({isActive}) => isActive ? 'footer_item c-greenBlue' : 'footer_item c-white'} to="/privacy-policy">Privacy Policy</NavLink>
                    </div>
                    <div className="fs-14 f-400 c-white">Copyright @ 2022 AdStock, All Rights Reserved</div>
                </div>
            </div>
            </div>
        </footer>

    <footer className="bc-blue footer_cont_mobile">
        <div className="container">
            <div className="container_inner">
            <div className="footer_cont_inner">
                <div className="fs-18 d-flex fd-column footer_items_block">
                    <div className="d-flex justify-content-center">
                        <NavLink className={({isActive}) => isActive ? 'footer_item c-greenBlue' : 'footer_item c-white'} to="/refund-policy" >Refund Policy</NavLink>
                        <NavLink className={({isActive}) => isActive ? 'footer_item c-greenBlue' : 'footer_item c-white'} to="/cookie-policy">Cookie policy</NavLink>
                    </div>
                 <div className="d-flex justify-content-center">
                     <NavLink className={({isActive}) => isActive ? 'footer_item c-greenBlue' : 'footer_item c-white'} to="/terms">Terms of Service</NavLink>
                     <NavLink className={({isActive}) => isActive ? 'footer_item c-greenBlue' : 'footer_item c-white'} to="/privacy-policy">Privacy Policy</NavLink>
                 </div>
                </div>
                <div className="fs-14 f-400 c-white d-flex align-items-center justify-content-center all_rights">Copyright @ 2022 AdStock, All Rights Reserved</div>
            </div>
        </div>
        </div>
    </footer>
        </>
    )
}
