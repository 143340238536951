import "./style.css"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SearchAdsFilter from "../../components/SearchAdsFilter/SearchAdsFilter";
import SearchItems from "../../components/SearchItems/SearchItems";
import {useEffect, useState, useMemo} from "react";
import axios from "axios";
import config from "../../config";
import useDocumentTitle from "../../useDocumentTitle";

export default function FeedPage() {
    useDocumentTitle('Feed Page')
    let token = localStorage.getItem('jwtToken')
    let [videos, setVideos] = useState([])
    let [searchText, setSearchText] = useState('')
    let [filterItems, setFilterItems] = useState({})



    let addFavourites = (index) => {
        videos[index].favourite = !videos[index].favourite
        setVideos([...videos])
        if (videos[index].favourite) {
            axios.get(`${config.url}/add/favorite/${videos[index].id}`, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            })
                .then(response => {

                }).catch(error => {
                console.log(error.response)
            })
        } else {
            axios.delete(`${config.url}/add/favorite/delete/${videos[index].id}`, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log(response);
                }).catch(error => {
                console.log(error.response)
            })
        }
    }

    useEffect(() => {
        filterItemsReq()
        axios.post(`${config.url}/videos`, {}, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }
        ).then(response => {
            addToFavourites(response.data.videos)
        }).catch(error => {
            console.log(error.response)
        })
    }, [])


    let addToFavourites = (item) => {

        axios.get(`${config.url}/get/favorites`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data.favorite
                console.log(data, "data of addfavourite")
                let arr = item
                for(let i = 0; i < arr.length; i++){
                    for(let j = 0; j < data.length; j++){
                        if(arr[i].id === data[j].video_id){
                            arr[i].favourite = true
                        }
                    }
                }
                console.log(videos, 'videos');
                setVideos(arr)
            }).catch(error => {
            console.log(error.response)
        })
    }

    const filterItemsReq =()=>{
        axios.get(`${config.url}/filter`, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }
        ).then(response => {
             setFilterItems({...response.data})

        }).catch(error => {
            console.log(error.response)
        })
    }

    let filterReq = async (filterData) => {
        try {
            axios.post(`${config.url}/videos`, filterData, {
                      headers: {
                          'authorization': `Bearer ${token}`
                      }
                  }
              ).then(response => {
                addToFavourites(response.data.videos)
              }).catch(error => {
                  console.log(error.response)
              })
        } catch (e) {

        }
    }

    const filteredDirectory = useMemo(() => videos.filter((video) =>
            video.title ? video.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 : false),
        [searchText, videos]);

    return (
        <div className="main">
            <div>
                <Header/>
                <div className="container">
                    <div className="container_inner">
                        <p className="f-700 fs-32" style={{marginTop: "48px"}}>Search for ads</p>
                        <SearchAdsFilter filterReq={filterReq} filterItems={filterItems}/>
                        <SearchItems addFavourites={addFavourites} videos={filteredDirectory} page={'Feed'}/>
                        <div className="load_more_btn_block d-flex justify-content-center">
                            <button className="load_more_btn fs-16 f-400">Load more</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
