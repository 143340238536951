import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/zoom";
import {Navigation} from "swiper";
import "./style.css"



// Import Swiper styles
import 'swiper/css';

export default function SwiperSlider(props){
    return (
        <Swiper
            style={{
                "--swiper-navigation-color": "#1E376F",
                "--swiper-pagination-color": "#fff",
            }}
            breakpoints={{
                576: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                },
            }}
            // spaceBetween={50}
            // slidesPerView={3}
            effect={"fade"}
            pagination={{
                clickable: true,
            }}
            rewind={true}
            navigation={true}
            modules={[Navigation]}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
                {props.reviews.map((item,index)=> {
                    return (
                            <SwiperSlide className="reviews_cont_item" key={item.id}>
                                <div className="reviews_cont_item_inner d-flex fd-column
                                justify-content-start">
                                    <p className="f-700 fs-18 reviews_cont_item_title">{item.author}</p>
                                    <div className="reviews_stars d-flex align-items-center fs-18">{item.stars}</div>
                                    <p className="f-400 fs-16">{item.opinion}</p>
                                </div>
                            </SwiperSlide>
                    )
                })
                }
        </Swiper>
    )
};